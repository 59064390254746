@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

body.dark-theme {
  background-color: #242424;
}

$dark-primary: mat.define-palette(mat.$deep-purple-palette, 300);
$dark-accent: mat.define-palette(mat.$deep-purple-palette, 100);
$dark-warn: mat.define-palette(mat.$red-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

.krya-theme {
  @include mat.all-component-themes($dark-theme);
}

$dark-primary: mat.define-palette(mat.$cyan-palette, 500);
$dark-accent: mat.define-palette(mat.$gray-palette, 300);
$dark-warn: mat.define-palette(mat.$red-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

.leek-theme {
  @include mat.all-component-themes($dark-theme);
}

$dark-primary: mat.define-palette(mat.$blue-palette, 500);
$dark-accent: mat.define-palette(mat.$purple-palette, A400);
$dark-warn: mat.define-palette(mat.$lime-palette, 500);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

.accessible-theme {
  @include mat.all-component-themes($dark-theme);
}
