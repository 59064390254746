body {
    margin: 0;
    height: 100%;
}

html {
    height: 100%;
}

* {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
    background: #303030;
    height: 4px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #242424;
    height: 4px;
}
